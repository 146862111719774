/* eslint-disable no-useless-escape */
export const companyNamePattern =
    /^[A-Za-z0-9а-яА-Я][A-Za-z0-9а-яА-Я\s-&@£$€¥,;:!?`\.'\"“\[\]\{\}\(\)<>«»\\\/]+$/;

export const nameValidationPattern = /^([a-zA-Zа-яА-Я])[a-zA-Zа-яА-Я ',. -]+[a-zA-Zа-яА-Я]$/;

export const jobNameValidationPattern =
    /^([a-zA-Zа-яА-Я\.])[a-zA-Zа-яА-я\d\ \-,.+///\\\\|()&@:#`']*$/;

export const mailValidationPattern =
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const phoneValidationPattern = /^\+?((\()?[\d]{1,3}(\))?)?([\.\-\d ]?){6,16}[\d]$/;

export const linksValidationPattern =
    /^((h|H)(t|T)(t|T)(p|P):\/\/(w|W)(w|W)(w|W)\.|(h|H)(t|T)(t|T)(p|P)(s|S):\/\/(w|W)(w|W)(w|W)\.|(h|H)(t|T)(t|T)(p|P):\/\/|(h|H)(t|T)(t|T)(p|P)(s|S):\/\/)?[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

export const urlRegex =
    /^((https?|ftp|smtp):\/\/)?(www.)?[a-zA-Z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

export const portValidationPattern =
    /^([1-9]\d{0,3}|0|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/;

export const hostValidationPattern =
    /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/;

export const dateRegexValidation = {
    'dd/mm/yy': /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/\d{4}$/,
    'dd-mm-y': /^(0[1-9]|[12][0-9]|3[01])-([A-ZА-Яa-zа-я]{3})-\d{2}$/,
    'dd-m-yy': /^(0[1-9]|[12][0-9]|3[01])-([A-ZА-Яa-zа-я]{3})-\d{4}$/,
    'dd.mm.y': /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.\d{2}$/,
    'dd.mm.yy': /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.\d{4}$/,
    'mm/dd/yy': /^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/,
    'mm/dd/y': /^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/\d{2}$/,
    'yy/mm/dd': /^\d{4}\/(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])$/,
};
