import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { LOGIN_USER, LOGOUT_USER } from '../actions';

import { loginUserSuccess, loginUserError } from './actions';

import UserRequests from '../../helpers/requests/UserRequests';
import routes from '../../constants/routes';
import HttpRequests from '../../helpers/http/HttpRequests';

const loginWithEmailPasswordAsync = async (email, password) =>
    await UserRequests.login(email, password)
        .then((response) => response)
        .catch((err) => err.response);

function* loginWithEmailPassword({ payload }) {
    const { email, password } = payload.user;
    const { history, messages } = payload;
    try {
        const response = yield call(loginWithEmailPasswordAsync, email, password);
        if (response.status === 200) {
            localStorage.setItem('access_token', response.data['access_token']);
            localStorage.setItem('refresh_token', response.data['refresh_token']);
            yield put(loginUserSuccess(response.data));
            history.push(routes.app.path);
        } else {
            HttpRequests.handleError(response, messages, 'Login failed', history);
            yield put(loginUserError(response.data.error_description));
        }
    } catch (error) {
        yield put(loginUserError(error.error_description));
    }
}

const logoutAsync = async (history) => {
    // await UserRequests.logout().catch(error => error);
    // history.push('/')
};

function* logout({ payload }) {
    const { history } = payload;
    try {
        yield call(logoutAsync, history);
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('id_token');
        history.push('/user/logout');
    } catch (error) {
        console.log(error);
    }
}

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

export default function* rootSaga() {
    yield all([fork(watchLoginUser), fork(watchLogoutUser)]);
}
