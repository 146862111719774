import { domain, prefix } from './config';

class Endpoints {
    /*User management*/
    static login = () => `${domain}oauth/token`;
    static logout = () => `${domain}${prefix}token/logout`;
    static setPassword = uid => `${domain}${prefix}public/invitations/set-password?uid=${uid}`;
    static verifyInvitationToken = uid => `${domain}${prefix}public/invitations?uid=${uid}`;
    static verifyResetPasswordToken = uid => `${domain}${prefix}public/reset-password?token=${uid}`;
    static forgotPassword = () => `${domain}${prefix}public/reset-password`;
    static resetForgotPassword = uid => `${domain}${prefix}public/reset-password?token=${uid}`;
    static sendInvitation = () => `${domain}${prefix}invitations`;
    static bulkParseUserList = () => `${domain}${prefix}invitations/bulk-parse`;
    static bulkInviteUsers = () => `${domain}${prefix}invitations/bulk-invite`;
    static resendInvitation = () => `${domain}${prefix}invitations`;
    static getUserRoles = () => `${domain}${prefix}roles`;
    static getUsersList = () => `${domain}${prefix}users/list`;
    static updateUser = uid => `${domain}${prefix}users/${uid}`;
    static getUser = uid => `${domain}${prefix}users/${uid}`;
    static getUserProfile = () => `${domain}${prefix}users/profile`;
    static updateUserProfile = () => `${domain}${prefix}users/profile`;
    static changePassword = () => `${domain}${prefix}users/change-password`;
    static updateUserPhoto = () => `${domain}${prefix}users/profile/files`;
    static deletePhoto = () => `${domain}${prefix}users/profile/files`;
    static getUsersByPermissions = () => `${domain}${prefix}users/permissions`;

    static testSMTPConnection = () => `${domain}${prefix}user-settings/mail-box-validation`;
    static configureMailBox = () => `${domain}${prefix}user-settings/mail-box`;
    static getMailBox = () => `${domain}${prefix}user-settings/mail-box`;

    static getAssignedTasks = () => `${domain}${prefix}tasks/assigned`;
    static getCreatedTasks = () => `${domain}${prefix}tasks/everyone`;
    static getInfoCards = () => `${domain}${prefix}tasks/info-cards`;

    static getJobsPublic = () => `${domain}${prefix}jobs/public/list`;
    static getJobs = () => `${domain}${prefix}jobs/list`;
    static getDropdownJobs = () => `${domain}${prefix}jobs/simple-list`;
    static getJob = id => `${domain}${prefix}jobs/${id}`;
    static cloneJob = (id, title) => `${domain}${prefix}jobs/${id}/clone?title=${title}`; // Clone an existing job
    static getJobPublic = id => `${domain}${prefix}jobs/${id}/public/preview`;
    static getJobTemplatePublic = id => `${domain}${prefix}jobs/${id}/public/application`;
    static getJobStatuses = () => `${domain}${prefix}job-statuses/list`;

    static getEmploymentTypesPerJob = id =>
        `${domain}${prefix}settings/employment/list?jobId=${id}`;
    static getEmploymentTypes = () => `${domain}${prefix}settings/employment/list`;
    static createEmploymentType = () => `${domain}${prefix}settings/employment`;
    static updateEmploymentType = id => `${domain}${prefix}settings/employment/${id}`;
    static deleteEmploymentType = id => `${domain}${prefix}settings/employment/${id}`;

    static getExperiences = () => `${domain}${prefix}experiences/list`;
    static getEducations = () => `${domain}${prefix}educations/list`;
    static archiveJob = id => `${domain}${prefix}jobs/${id}/archive`;
    static createJob = id => `${domain}${prefix}jobs?companyId=${id}`;
    static updateJob = id => `${domain}${prefix}jobs/${id}`;

    static getJobNotesByJobId = id => `${domain}${prefix}jobs/${id}/notes/list`;
    static createJobNote = id => `${domain}${prefix}jobs/${id}/notes`;
    static updateJobNote = (id, noteId) => `${domain}${prefix}jobs/${id}/notes/${noteId}`;
    static deleteJobNote = (id, noteId) => `${domain}${prefix}jobs/${id}/notes/${noteId}`;

    static getJobFiles = id => `${domain}${prefix}jobs/${id}/files`;
    static createJobFile = id => `${domain}${prefix}jobs/${id}/files`;
    static downloadJobFile = id => `${domain}${prefix}jobs/files/${id}`;
    static deleteJobFile = id => `${domain}${prefix}jobs/files/${id}`;

    static getPermissions = () => `${domain}${prefix}permissions`;
    static updateRolePermissions = id => `${domain}${prefix}roles/${id}`;
    static deleteUserRole = id => `${domain}${prefix}roles/${id}`;
    static createUserRole = () => `${domain}${prefix}roles`;
    static getPipeline = id => `${domain}${prefix}pipelines/${id}`;
    static updatePipeline = id => `${domain}${prefix}pipelines/${id}`;
    static createPipeline = () => `${domain}${prefix}pipelines`;
    static getDefaultPipeline = () => `${domain}${prefix}pipelines/default`;
    static createStage = pipelineId => `${domain}${prefix}pipelines/${pipelineId}/stages`;
    static updateStage = (pipelineId, id) =>
        `${domain}${prefix}pipelines/${pipelineId}/stages/${id}`;
    static deleteStage = (pipelineId, id) =>
        `${domain}${prefix}pipelines/${pipelineId}/stages/${id}`;
    static getStageNames = () => `${domain}${prefix}pipelines/stages`;

    static getCompanies = () => `${domain}${prefix}companies/list`;
    static createCompany = () => `${domain}${prefix}companies`;
    static updateCompany = id => `${domain}${prefix}companies/${id}`;
    static updateCompanyLogo = id => `${domain}${prefix}companies/${id}/logos`;
    static deleteCompany = id => `${domain}${prefix}companies/${id}`;
    static getCompany = id => `${domain}${prefix}companies/${id}`;
    static getDisqualifyReasons = () => `${domain}${prefix}disqualify-reasons/list`;
    static createDisqualifyReasons = () => `${domain}${prefix}disqualify-reasons`;
    static deleteDisqualifyReasons = id => `${domain}${prefix}disqualify-reasons/${id}`;
    static updateDisqualifyReasons = id => `${domain}${prefix}disqualify-reasons/${id}`;
    static reorderDisqualifyReasons = () => `${domain}${prefix}disqualify-reasons/reorder`;

    static getLocations = () => `${domain}${prefix}locations/list`;
    static getCandidatesList = () => `${domain}${prefix}job-candidates/list`;
    static getJobCandidatesList = (jobId, isQualified, locationId) =>
        `${domain}${prefix}job-candidates/candidates-list?jobId=${jobId}&isQualified=${isQualified}${
            locationId ? `&locationId=${locationId}` : ''
        }`;
    static getJobCandidatesCount = (jobId, isQualified, locationId) =>
        `${domain}${prefix}job-candidates/count?jobId=${jobId}&isQualified=${isQualified}${
            locationId ? `&locationId=${locationId}` : ''
        }`;
    static getCandidate = id => `${domain}${prefix}candidates/${id}`;
    static getJobCandidate = id => `${domain}${prefix}job-candidates/${id}`;
    static getJobCandidateFileById = (id, candidateId) =>
        `${domain}${prefix}candidates/${candidateId}/file/${id}?fileId=${id}`;
    static getCandidatesCount = id => `${domain}${prefix}job-candidates/${id}/total-count`;
    static updateCandidatesList = id => `${domain}${prefix}job-candidates/${id}`;
    static updateCandidate = id => `${domain}${prefix}candidates/${id}`;
    static deleteCandidate = id => `${domain}${prefix}candidates/${id}`;
    static createCandidate = () => `${domain}${prefix}candidates`;
    static getEventTypes = () => `${domain}${prefix}scheduled-events/types/list`;
    static getEvents = () => `${domain}${prefix}scheduled-events/list`;
    static createEvent = () => `${domain}${prefix}scheduled-events`;
    static updateEvent = id => `${domain}${prefix}scheduled-events/${id}`;
    static deleteEvent = id => `${domain}${prefix}scheduled-events/${id}`;
    static createCandidateTask = () => `${domain}${prefix}tasks`;
    static updateCandidateTask = id => `${domain}${prefix}tasks/${id}`;
    static deleteCandidateTask = id => `${domain}${prefix}tasks/${id}`;
    static deleteEvaluation = (candidateId, evalId) =>
        `${domain}${prefix}job-candidates/${candidateId}/evaluation/${evalId}`;
    static updateEvaluation = (candidateId, evalId) =>
        `${domain}${prefix}job-candidates/${candidateId}/evaluation/${evalId}`;
    static getCandidateHrNotes = candidateId =>
        `${domain}${prefix}candidates/${candidateId}/hr-notes/list`;
    static getTaskComments = taskId => `${domain}${prefix}tasks/${taskId}/comments`;
    static createTaskComment = taskId => `${domain}${prefix}tasks/${taskId}/comments`;
    static editTaskComment = commentId => `${domain}${prefix}tasks/comments/${commentId}`;
    static deleteTaskComment = commentId => `${domain}${prefix}tasks/comments/${commentId}`;
    static createCandidateHrNotes = candidateId =>
        `${domain}${prefix}candidates/${candidateId}/hr-notes`;
    static updateCandidateHrNotes = (candidateId, noteId) =>
        `${domain}${prefix}candidates/${candidateId}/hr-notes/${noteId}`;
    static deleteCandidateHrNotes = (candidateId, noteId) =>
        `${domain}${prefix}candidates/${candidateId}/hr-notes/${noteId}`;
    static uploadCandidateFiles = candidateId =>
        `${domain}${prefix}candidate-files/${candidateId}/files`;
    static getCandidateFiles = candidateId =>
        `${domain}${prefix}candidate-files/${candidateId}/files`;
    static deleteCandidateFile = (candidateId, fileId) =>
        `${domain}${prefix}candidate-files/${candidateId}/files/${fileId}`;
    static downloadCandidateFile = (candidateId, fileId) =>
        `${domain}${prefix}candidate-files/${candidateId}/files/${fileId}`;

    static getCandidateNotes = candidateId =>
        `${domain}${prefix}candidates/${candidateId}/notes/list`;
    static createCandidateNotes = candidateId =>
        `${domain}${prefix}candidates/${candidateId}/notes`;
    static updateCandidateNotes = (candidateId, noteId) =>
        `${domain}${prefix}candidates/${candidateId}/notes/${noteId}`;
    static deleteCandidateNotes = (candidateId, noteId) =>
        `${domain}${prefix}candidates/${candidateId}/notes/${noteId}`;
    static createEvalScore = jobCandidateId =>
        `${domain}${prefix}job-candidates/${jobCandidateId}/evaluation`;
    static getEvalScore = jobCandidateId =>
        `${domain}${prefix}job-candidates/${jobCandidateId}/evaluation`;
    static getEvalSummary = jobCandidateId =>
        `${domain}${prefix}job-candidates/${jobCandidateId}/evaluation/summary`;

    static getCandidateTasks = () => `${domain}${prefix}tasks/list`;
    static createCandidatePublic = () => `${domain}${prefix}candidates/public/new`;
    static bulkAction = () => `${domain}${prefix}job-candidates/bulk-actions`;
    static getCandidates = () => `${domain}${prefix}candidates/list`;
    static getCandidatesDropdown = () => `${domain}${prefix}candidates/simple-list`;

    static createLocation = () => `${domain}${prefix}locations`;
    static updateLocation = id => `${domain}${prefix}locations/${id}`;
    static getLocation = id => `${domain}${prefix}locations/${id}`;
    static deleteLocation = id => `${domain}${prefix}locations/${id}`;

    static getDepartments = () => `${domain}${prefix}departments/list`;
    static createDepartment = () => `${domain}${prefix}departments`;
    static updateDepartment = id => `${domain}${prefix}departments/${id}`;
    static getDepartment = id => `${domain}${prefix}departments/${id}`;
    static deleteDepartment = id => `${domain}${prefix}departments/${id}`;

    static getSourcesList = () => `${domain}${prefix}settings/sources/list`;
    static getTimezones = () => `${domain}${prefix}settings/time-zones`;
    static getGlobalSettings = () => `${domain}${prefix}settings`;
    static getTimeFormats = () => `${domain}${prefix}settings/time-formats`;
    static getDateFormats = () => `${domain}${prefix}settings/date-formats`;
    static getLanguages = () => `${domain}${prefix}settings/languages`;
    static getPlatformLogo = () => `${domain}${prefix}settings/platform-logos`;
    static updateGlobalSettings = () => `${domain}${prefix}settings`;
    static updatePlatformLogo = () => `${domain}${prefix}settings/platform-logos`;
    static getWhitelistIPs = () => `${domain}${prefix}settings/whitelist-ip`;
    static getPlatformLogoPublic = () => `${domain}${prefix}settings/public/platform-logos`;
    static getProjectVersion = () => `${domain}version`;
    static getGdprSettings = () => `${domain}${prefix}gdpr-settings`;

    static getSources = () => `${domain}${prefix}settings/sources/list`;
    static createSources = () => `${domain}${prefix}settings/sources`;
    static updateSource = id => `${domain}${prefix}settings/sources/${id}`;
    static deleteSource = id => `${domain}${prefix}settings/sources/${id}`;

    static getJobActivityLog = id => `${domain}${prefix}jobs/${id}/activity-logs`;
    static getCandidateActivityLog = id => `${domain}${prefix}candidates/${id}/activity-log`;
    static getJobLinks = id => `${domain}${prefix}jobs/${id}/links`;
    static getJobUsers = id => `${domain}${prefix}jobs/${id}/users`;

    static getCandidateResume = id => `${domain}${prefix}candidates/${id}/resume`;
    static deleteCandidateResume = id => `${domain}${prefix}candidates/${id}/resume`;

    /*Reporting*/
    static getOverviewReporting = () => `${domain}${prefix}reports`;
    static getMembersTaskReport = () => `${domain}${prefix}team-reports/tasks`;
    static getMembersActivityReport = () => `${domain}${prefix}team-reports/activities`;
    static getMembersCandidatesReport = () => `${domain}${prefix}team-reports/candidates`;

    static getCandidateOverviewReport = () => `${domain}${prefix}candidate-reports`;

    static getJobCandidateReporting = id =>
        `${domain}${prefix}job-candidates/reports/sources?jobId=${id}`;
    static getJobCandidateDropOff = id =>
        `${domain}${prefix}job-candidates/reports/drop-off?jobId=${id}`;
    static getJobCandidateProceedReport = id =>
        `${domain}${prefix}job-candidates/reports/proceed?jobId=${id}`;
    static getJobCandidatePipelineSpeedReport = id =>
        `${domain}${prefix}job-candidates/reports/pipeline-speed?jobId=${id}`;
    static getJobCandidateSummaryReport = id =>
        `${domain}${prefix}job-candidates/reports/summary?jobId=${id}`;

    static getTeamReportsCandidates = data =>
        `${domain}${prefix}team-reports/candidates?memberId=${data.memberId}&action=${data.action}${
            data.fromDate ? `&fromDate=${data.fromDate}` : ''
        }${data.toDate ? `&toDate=${data.toDate}` : ''}`;
    static getTeamReportsCandidatesStages = data =>
        `${domain}${prefix}team-reports/candidates/stages?memberId=${data.memberId}&stage=${
            data.action
        }${data.fromDate ? `&fromDate=${data.fromDate}` : ''}${
            data.toDate ? `&toDate=${data.toDate}` : ''
        }`;

    static getCandidatesProgressJobsReport = () => `${domain}${prefix}job-candidates/progress/jobs`;
    static getCandidatesProgressCompaniesReport = () =>
        `${domain}${prefix}job-candidates/progress/companies`;
    static getCandidatesProgressSourcesReport = () =>
        `${domain}${prefix}job-candidates/progress/sources`;
    static getCandidatesProgressMonthsReport = () =>
        `${domain}${prefix}job-candidates/progress/months`;
    static getCandidatesProgressReportCandidates = () =>
        `${domain}${prefix}job-candidates/progress/candidates`;

    static getCandidatesReportsMonthsList = () =>
        `${domain}${prefix}job-candidates/progress/months/list`;

    static getCandidatesAverageTimeJobsReport = () => `${domain}${prefix}candidate-avg-time/job`;
    static getCandidatesAverageTimeCompaniesReport = () =>
        `${domain}${prefix}candidate-avg-time/company`;
    static getCandidatesAverageTimeSourcesReport = () =>
        `${domain}${prefix}candidate-avg-time/source`;
    static getCandidatesAverageTimeMonthsReport = () =>
        `${domain}${prefix}candidate-avg-time/month`;

    /* Talent Pools */
    static getTalentPools = () => `${domain}${prefix}talent-pools/list`;
    static getSimpleTalentPools = () => `${domain}${prefix}talent-pools/simple-list`;
    static talentPoolsList = () => `${domain}${prefix}talent-pools`;
    static talentPoolsDetail = id => `${domain}${prefix}talent-pools/${id}`;
    static talentPoolCandidates = () => `${domain}${prefix}talent-pool-candidates/list`;

    static gdprRequest = () => `${domain}${prefix}public/gdpr-request`;
    static gdprTokenRequest = token => `${domain}${prefix}public/gdpr-request?token=${token}`;
    static candidatesGdprReqests = () => `${domain}${prefix}candidates/gdpr-requests`;
    static candidateGdprReqests = id => `${domain}${prefix}candidates/${id}/gdpr-requests`;

    static getActivities = () => `${domain}${prefix}activities/list`;

    static cvExtract = () => `${domain}${prefix}extract`;

    static disqualifiedEmailSchedule = () => `${domain}${prefix}disqualify-notification`;
    static getDisqualifiedEmailsList = () => `${domain}${prefix}disqualify-notification/list`;
    static deleteDisqualifiedEmailById = id => `${domain}${prefix}disqualify-notification/${id}`;

    static getTeamMembersForJobIds = jobIds =>
        `${domain}${prefix}jobs/team-members?jobIds=${jobIds}`;

    /* Labels */
    static updateCandidateLabelRelations = id =>
        `${domain}${prefix}candidate-label/candidate/${id}`;
    static findLabelsByName = labelName => `${domain}${prefix}label?labelName=${labelName}`;

    /* Task Templates */
    static getTaskTemplates = () => `${domain}${prefix}task-template/list`;
    static createTaskTemplate = () => `${domain}${prefix}task-template`;
    static deleteTemplateById = id => `${domain}${prefix}task-template/${id}`;
    static createTemplateQuestion = templateId =>
        `${domain}${prefix}task-template/${templateId}/question`;
    static bulkUpdateQuestions = () => `${domain}${prefix}task-template/question/bulk`;
    static deleteQuestionById = id => `${domain}${prefix}task-template/question/${id}`;
    static updateTaskTemplate = id => `${domain}${prefix}task-template/${id}`;
    static getAllAnswersForTaskId = id => `${domain}${prefix}task-template/answer?taskId=${id}`;
    static updateAnswer = id => `${domain}${prefix}task-template/answer/${id}`;
    static updateMultipleAnswers = () => `${domain}${prefix}task-template/answer`;
    static updateTaskTemplateQuestion = id => `${domain}${prefix}task-template/question/${id}`;
    static getTaskTemplatesByJobId = jobId =>
        `${domain}${prefix}task-template/${jobId}/task-templates`;

    /* Job questionnaires */
    static getJobQuestionnairesList = () => `${domain}${prefix}job-questionnaires/job/list`;
    static addJobQuestionnaires = jobId =>
        `${domain}${prefix}job-questionnaires/job/${jobId}/questionnaires`;
    static removeJobQuestionnaires = jobId =>
        `${domain}${prefix}job-questionnaires/job/${jobId}/questionnaires`;
    static getRelatedQuestionnairesByJobId = jobId =>
        `${domain}${prefix}job-questionnaires/job/${jobId}/task-templates`;
}

export default Endpoints;
