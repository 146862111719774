class AppRoutes {
    static base = {
        path: '/',
        key: 'base',
    };

    static app = {
        path: '/app',
        key: 'app',
    };

    static notFound = {
        path: '/404',
        key: '404',
    };

    static unauthorized = {
        path: '/unauthorized',
        key: 'unauthorized',
    };

    static login = {
        path: '/user/login',
        key: 'login',
    };

    static register = {
        path: '/register',
        key: 'register',
    };

    static forgottenPassword = {
        path: '/forgotten-password',
        key: 'forgotten-password',
    };

    static teamMembers = {
        path: '/app/settings/team-members',
        key: 'team-members',
    };

    static jobView = {
        path: '/view',
        key: 'job-view',
    };

    static locations = {
        path: '/app/settings/locations',
        key: 'locations',
    };

    static jobCreate = {
        path: '/app/job/create',
        key: 'jobCreate',
    };

    static overviewCandidate = {
        path: '/app/candidates/candidate',
        key: 'overviewCandidate',
    };

    static job = {
        path: '/app/job',
        key: 'job',
    };

    static jobUpdate = {
        path: '/app/job',
        key: 'jobUpdate',
    };

    static departments = {
        path: '/app/settings/departments',
        key: 'departments',
    };

    static companies = {
        path: '/app/settings/companies',
        key: 'companies',
    };

    static listCandidates = {
        path: '/app/candidates/list',
        key: 'listCandidates',
    };

    static listEvents = {
        path: '/app/overview/events',
        key: 'listEvents',
    };

    static listTasks = {
        path: '/app/overview/tasks',
        key: 'listTasks',
    };

    static createCandidate = {
        path: '/app/candidates/create',
        key: 'createCandidate',
    };

    static talentPools = {
        path: '/app/talent-pools',
        key: 'talentPools',
    };
}

export default AppRoutes;
