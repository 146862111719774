import axios from 'axios';
// import { AxiosError } from 'axios';
// import {loadProgressBar}    from 'axios-progress-bar';
/*import { Messages }         from 'primereact/messages';*/
import axiosInstance from './AxiosInstance';
import RecruitService from '../../classes/recruit.service';

/*loadProgressBar({ showSpinner: false }, axiosInstance);*/

export default class HttpRequests {
    static post = (endpoint, data, config = undefined, useDefaultHttpInstance = undefined) => {
        endpoint = RecruitService.appendQueryParameter(
            endpoint,
            'lang',
            localStorage.getItem('currentLanguage')
        );
        if (typeof useDefaultHttpInstance !== 'undefined' && !useDefaultHttpInstance) {
            return axios.post(endpoint, data, config);
        }
        return axiosInstance.post(endpoint, data, config);
    };

    static put = (endpoint, data, config) => {
        endpoint = RecruitService.appendQueryParameter(
            endpoint,
            'lang',
            localStorage.getItem('currentLanguage')
        );

        return axiosInstance.put(endpoint, data, config);
    };

    static get = (endpoint, config, useDefaultHttpInstance) => {
        endpoint = RecruitService.appendQueryParameter(
            endpoint,
            'lang',
            localStorage.getItem('currentLanguage')
        );

        if (typeof useDefaultHttpInstance !== 'undefined' && !useDefaultHttpInstance) {
            return axios.get(endpoint, config);
        }
        return axiosInstance.get(endpoint, config);
    };

    static delete = (endpoint, config) => {
        endpoint = RecruitService.appendQueryParameter(
            endpoint,
            'lang',
            localStorage.getItem('currentLanguage')
        );

        return axiosInstance.delete(endpoint, config);
    };

    static getErrorMessage = (error, defaultMessage) => {
        const errorResponse = error.response ? error.response : error;

        if (
            errorResponse &&
            errorResponse.data &&
            (errorResponse.data.message || errorResponse.data.error_description)
        ) {
            return errorResponse.data.message
                ? errorResponse.data.message
                : errorResponse.data.error_description;
        } else {
            return defaultMessage;
        }
    };

    static handleError = (error, messageBlock, defaultMessage) => {
        const message = HttpRequests.getErrorMessage(error, defaultMessage);

        messageBlock.show({
            life: 5000,
            severity: 'error',
            detail: error.response?.status == 500 ? 'Something went wrong...' : message,
        });
    };

    static handleSuccess = (messageBlock, defaultMessage) => {
        messageBlock.show({ life: 5000, severity: 'success', detail: defaultMessage });
    };
}
