import Endpoints from '../../constants/endpoints';
import HttpRequests from '../http/HttpRequests';
import qs from 'qs';
import { HttpRequestsConfig } from '../http/HttpRequestsConfig';
// import { HttpRequestsConfig } from '../http/HttpRequestsConfig';

export default class UserRequests {
    static login = (email, password) => {
        return HttpRequests.post(
            Endpoints.login(),
            qs.stringify({
                username: email,
                password: password,
                grant_type: 'password',
            }),
            {
                headers: {
                    Authorization:
                        'Basic ' + btoa(unescape(encodeURIComponent('client_id:client_secret'))),
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
        );
    };

    static refreshAccessToken = refreshToken => {
        return HttpRequests.post(
            Endpoints.login(),
            qs.stringify({
                grant_type: 'refresh_token',
                refresh_token: refreshToken,
            }),
            {
                headers: {
                    Authorization:
                        'Basic ' + btoa(unescape(encodeURIComponent('client_id:client_secret'))),
                },
            },
            false
        );
    };

    static registerInvitedUser = (data, key) => {
        return HttpRequests.post(Endpoints.setPassword(key), data);
    };

    static verifyInvitationToken = key => {
        return HttpRequests.get(Endpoints.verifyInvitationToken(key));
    };

    static updateUser = (userId, data) => {
        let token = localStorage.getItem('access_token');
        let headers = {
            Authorization: `Bearer ${token}`,
        };
        return HttpRequests.put(
            Endpoints.updateUser(userId),
            data,
            new HttpRequestsConfig({
                headers: headers,
            })
        );
    };

    static deletePicture = pictureId => {
        let token = localStorage.getItem('access_token');
        let headers = {
            Authorization: `Bearer ${token}`,
        };
        return HttpRequests.delete(
            Endpoints.deletePhoto(pictureId),
            new HttpRequestsConfig({
                headers: headers,
            })
        );
    };

    static getUser = userId => {
        let token = localStorage.getItem('access_token');
        let headers = {
            Authorization: `Bearer ${token}`,
        };
        return HttpRequests.get(
            Endpoints.getUser(userId),
            new HttpRequestsConfig({
                headers: headers,
            })
        );
    };

    static resendInvitation = data => {
        let token = localStorage.getItem('access_token');
        let headers = {
            Authorization: `Bearer ${token}`,
        };
        return HttpRequests.put(
            Endpoints.resendInvitation(),
            data,
            new HttpRequestsConfig({
                headers: headers,
            })
        );
    };

    static getProfileData = () => {
        let token = localStorage.getItem('access_token');
        let headers = {
            Authorization: `Bearer ${token}`,
        };
        return HttpRequests.get(
            Endpoints.getUserProfile(),
            new HttpRequestsConfig({
                headers: headers,
            })
        );
    };

    static updateProfile = data => {
        let token = localStorage.getItem('access_token');
        let headers = {
            Authorization: `Bearer ${token}`,
        };
        return HttpRequests.put(
            Endpoints.updateUserProfile(),
            data,
            new HttpRequestsConfig({
                headers: headers,
            })
        );
    };

    static changePassword = data => {
        let token = localStorage.getItem('access_token');
        let headers = {
            Authorization: `Bearer ${token}`,
        };
        return HttpRequests.post(
            Endpoints.changePassword(),
            data,
            new HttpRequestsConfig({
                headers: headers,
            })
        );
    };

    static uploadUserPhoto = (userId, data) => {
        let token = localStorage.getItem('access_token');
        let headers = {
            Authorization: `Bearer ${token}`,
        };
        return HttpRequests.put(
            Endpoints.updateUserPhoto(userId),
            data,
            new HttpRequestsConfig({
                headers: headers,
            })
        );
    };

    static getUsersByPermissions = data => {
        let token = localStorage.getItem('access_token');
        let headers = {
            Authorization: `Bearer ${token}`,
        };
        return HttpRequests.post(
            Endpoints.getUsersByPermissions(),
            data,
            new HttpRequestsConfig({
                headers: headers,
            })
        );
    };

    static logout = () => {
        let token = localStorage.getItem('access_token');
        let headers = {
            Authorization: `Bearer ${token}`,
        };
        return HttpRequests.post(
            Endpoints.logout(),
            '',
            new HttpRequestsConfig({
                headers: headers,
            })
        );
    };

    static forgotPassword = data => {
        return HttpRequests.post(Endpoints.forgotPassword(), data);
    };
    static resetUserPassword = (data, key) => {
        return HttpRequests.put(Endpoints.resetForgotPassword(key), data);
    };

    static verifyResetPasswordToken = key => {
        return HttpRequests.get(Endpoints.verifyResetPasswordToken(key));
    };
}
