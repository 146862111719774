import axios                from 'axios'

axios.defaults.headers.common['X-Requested-With'] = `XMLHttpRequest`;
const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
    function(response) {
        return response
    },
    function(error) {
        const {response: {status}} = error;

        localStorage.setItem('origin_url', window.location.pathname + window.location.search) 

        switch (status) {
            case 401:
                return new Promise((resolve, reject) => {
                    window.location = window.location.origin + '/user/logout';
                });
            case 403:
                return new Promise((resolve, reject) => {
                    window.location = window.location.origin + '/unauthorized';
                });
            default:
                break;
        }
    
        return Promise.reject(error)
    }
);

export default axiosInstance;