export default class Files {  
    // Files mime types:
    // Generic pictues  
    static JPG           = 'image/jpeg,image/x-citrix-jpeg,image/pjpeg,';
    static PNG           = 'image/png,image/x-citrix-png,image/x-png,';
    static BMP           = 'image/bmp,';

    // Office 
    static DOC           = 'application/msword,';
    static DOCX          = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document,';
    static XLS           = 'application/vnd.ms-excel,';
    static XLSX          = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,';
    static ODT           = 'application/vnd.oasis.opendocument.text,';

    // Adobe
    static PDF           = 'application/pdf,';

    // Archives
    static ZIP           = 'application/zip,application/x-zip-compressed,';
    static RAR           = 'application/x-rar-compressed, application/octet-stream, application/x-rar,';
    static SEVEN_Z       = 'application/x-7z-compressed,';
    static TAR           = 'application/x-tar,';
    static BZIP          = 'application/x-bzip,';
    static BZIP_TWO      = 'application/x-bzip2,';

    //File extensions
    static EXT_RAR       = '.rar,';
    
    static checkFileTypeInAllTypes = (mimeType, fileName) => {
        const fileExtension = this.getFileExtension(fileName); // Needed for .rar files because they don't provide a mimetype
        
        return (this.EXT_RAR.indexOf(fileExtension)  !== -1 ||
                this.JPG.indexOf(mimeType)           !== -1 ||
                this.PNG.indexOf(mimeType)           !== -1 ||
                this.DOC.indexOf(mimeType)           !== -1 ||
                this.DOCX.indexOf(mimeType)          !== -1 ||
                this.PDF.indexOf(mimeType)           !== -1 ||
                this.ZIP.indexOf(mimeType)           !== -1 ||
                this.RAR.indexOf(mimeType)           !== -1);
    };

    static checkFileTypeInCVTypes = (mimeType) => {
        
        return (this.DOC.indexOf(mimeType)  !== -1 ||
                this.DOCX.indexOf(mimeType) !== -1 ||
                this.PDF.indexOf(mimeType)  !== -1);
    };

    static getFileExtension = (fileName) => {
        let fileExtension = fileName.split('.')[(fileName.split('.').length - 1)];
        return fileExtension;
    };
}
