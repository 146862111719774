import PropTypes from "prop-types";

export default class ElementConfig {
    className;
    placeholder;
    type;
    list;
    options;
    showTime;
    timeOnly;
    minDate;
    maxDate;
    hourFormat;
    futureDateValidation;
    items;
    selectedItems;
    maxSelectedItems;
    readonlyinput;
    labelText;
    isMulti;
    closeMenuOnSelect;
    dateRange;
    isClearable;
    noOptionsMessage;
    formatGroupLabel;
    disabled;
    beginLimit;
    endLimit;

    constructor(elementConfig) {
        if (elementConfig === undefined) {
            elementConfig = {
                'className': '',
                'placeholder': '',
                'type': '',
                'list': '',
                'options': [],
                'showTime': false,
                'timeOnly': false,
                'disabled': false,
                'minDate': null,
                'maxDate': null,
                'hourFormat': '',
                'futureDateValidation': false,
                'items': [],
                'selectedItems': [],
                'maxSelectedItems': 5,
                'readonlyinput': true,
                'labelText': '',
                'isMulti': false,
                'closeMenuOnSelect': true,
                'dateRange': false,
                'beginLimit': '',
                'endLimit': '',
            };
        }

        this.className              = elementConfig.className;
        this.placeholder            = elementConfig.placeholder;
        this.type                   = elementConfig.type;
        this.list                   = elementConfig.list;
        this.options                = elementConfig.options;
        this.showTime               = elementConfig.showTime;
        this.timeOnly               = elementConfig.timeOnly;
        this.disabled               = elementConfig.disabled;
        this.minDate                = elementConfig.minDate;
        this.maxDate                = elementConfig.maxDate;
        this.hourFormat             = elementConfig.hourFormat;
        this.futureDateValidation   = elementConfig.futureDateValidation;
        this.items                  = elementConfig.items;
        this.selectedItems          = elementConfig.selectedItems;
        this.maxSelectedItems       = elementConfig.maxSelectedItems;
        this.readonlyinput          = elementConfig.readonlyinput;
        this.labelText              = elementConfig.labelText;
        this.isMulti                = elementConfig.isMulti;
        this.closeMenuOnSelect      = elementConfig.closeMenuOnSelect;
        this.dateRange              = elementConfig.dateRange;
        this.isClearable            = elementConfig.isClearable;
        this.noOptionsMessage       = elementConfig.noOptionsMessage;
        this.formatGroupLabel       = elementConfig.formatGroupLabel;
        this.beginLimit             = elementConfig.beginLimit;
        this.endLimit               = elementConfig.endLimit;
    }
}

export class DropdownOption {
    label;
    value;
    id;

    constructor(dropdownOption) {
        if (dropdownOption === undefined) {
            dropdownOption = {
                'label': '',
                'value': '',
                'id': ''
            };
        }

        this.label = dropdownOption.label;
        this.value = dropdownOption.value;
        this.id = dropdownOption.id;
    }
}

ElementConfig.propTypes = {
    className                   : PropTypes.string,
    placeholder                 : PropTypes.string,
    type                        : PropTypes.string,
    options                     : PropTypes.array,
    showTime                    : PropTypes.bool,
    timeOnly                    : PropTypes.bool,
    disabled                    : PropTypes.bool,
    minDate                     : PropTypes.object,
    maxDate                     : PropTypes.object,
    hourFormat                  : PropTypes.string,
    futureDateValidation        : PropTypes.bool,
    items                       : PropTypes.array,
    selectedItems               : PropTypes.array,
    maxSelectedItems            : PropTypes.number,
    readonlyinput               : PropTypes.bool,
    labelText                   : PropTypes.string,
    isMulti                     : PropTypes.bool,
    closeMenuOnSelect           : PropTypes.bool,
    dateRange                   : PropTypes.bool,
    beginLimit                  : PropTypes.string,
    endLimit                    : PropTypes.string,
};
