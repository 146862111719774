/* eslint-disable no-useless-escape */
import moment from 'moment';
import 'moment-timezone';
import { DropdownOption } from './definitions/formItem/ElementConfig';
import * as _ from 'lodash';
import { localeOptions } from '../constants/config';
import Files from './files';
import {
    linksValidationPattern,
    mailValidationPattern,
    nameValidationPattern,
    phoneValidationPattern,
} from '../constants/regex';
import { update } from 'lodash';

export default class RecruitService {
    static DYNAMIC_FILE_ID = 11;
    static DYNAMIC_NAME_ID = 17;
    static DYNAMIC_WEBSITE_ID = 3;
    static DYNAMIC_RESUME_ID = 5;
    static DYNAMIC_PHONE_ID = 1;
    static DYNAMIC_EMAIL_ID = 18;
    static DYNAMIC_DESIRED_SALARY_ID = 14;
    static DYNAMIC_YES_NO_ID = 19;
    static DYNAMIC_DATE_AVAILABLE_ID = 13;
    static DYNAMIC_MULTIPLE_CHOICE_ID = 16;
    static DYNAMIC_SINGLE_CHOICE_ID = 15;
    static DYNAMIC_LINKEDIN_ID = 6;
    static DYNAMIC_COVER_LETTER_ID = 8;
    static DYNAMIC_TEXT_ID = 10;
    // Just a random number, added for the locations
    static DYNAMIC_LOCATIONS_ID = 1221;
    static datepickerFormat = 'dd/mm/yy'; // Use for datepicker only

    static checkFormValidity = formFields => {
        let formIsValid = true;
        const dateIsValid = true;

        for (const inputIdentifier in formFields) {
            if (formFields[inputIdentifier].valid !== undefined) {
                formIsValid = RecruitService.checkFormValidityFields(
                    formFields[inputIdentifier],
                    dateIsValid,
                    formIsValid
                );
            } else if (Array.isArray(formFields[inputIdentifier])) {
                for (const fieldset of formFields[inputIdentifier]) {
                    if (fieldset) {
                        for (const item of fieldset) {
                            if (item.valid !== undefined) {
                                formIsValid = RecruitService.checkFormValidityFields(
                                    item,
                                    dateIsValid,
                                    formIsValid
                                );
                            }
                        }
                    }
                }
            }
        }

        return formIsValid;
    };

    static checkFieldValidity = (value, rules, eventType) => {
        let isValid = true;

        if (value != null && typeof value !== 'undefined' && !(value instanceof Date)) {
            if (value.files !== undefined) {
                if (value.files[0].size === 0) {
                    isValid = false;
                }
            } else if (rules.required && value.size === 0) {
                isValid = false;
            } else if (rules.required && _.get(value, 'value.value') === '') {
                // Added for an empty dropdown
                isValid = false;
            } else if (rules.required && value.type && value.size === 0) {
                // Added for dynamic generated file inputs
                isValid = false;
            } else {
                if (
                    rules.required &&
                    (eventType === 'onChange' ||
                        eventType === 'onBlur' ||
                        eventType === 'onFakeEvent')
                ) {
                    isValid = value.toString().trim() !== '' && isValid;
                }
                if (
                    rules.pattern &&
                    value.trim() !== '' &&
                    (eventType === 'onBlur' || eventType === 'onFakeEvent')
                ) {
                    isValid = rules.pattern.test(value.trim()) && isValid;
                }
            }
        } else if (value != null && typeof value !== 'undefined' && value instanceof Date) {
            if (rules.futureDateValidation) {
                isValid = RecruitService.checkForFutureDate(value);
            }
        }

        return isValid;
    };

    static checkFormValidityFields(field, dateIsValid, formIsValid) {
        let validByPattern = true;
        const dateCheck = field.value instanceof Date;

        if (
            !dateCheck &&
            field.validation.pattern &&
            field.value &&
            field.value.toString().trim() !== ''
        ) {
            // Testing the value of the input if it has a regex pattern
            validByPattern = field.validation.pattern.test(field.value.toString().trim());
        } else if (dateCheck) {
            if (field.validation.futureDateValidation) {
                dateIsValid = RecruitService.checkForFutureDate(field.value);
            }
        }
        return field.valid && formIsValid && validByPattern && dateIsValid;
    }

    static validLang(language) {
        for (let i = 0; i < localeOptions.length; i++) {
            if (localeOptions[i].id === language) {
                return true;
            }
        }
        return false;
    }

    static appendQueryParameter(endpoint, key, value) {
        if (endpoint.includes('?')) {
            return endpoint + `&${key}=${value}`;
        }
        return endpoint + `?${key}=${value}`;
    }

    static checkForFutureDate(dateTime) {
        const currentTime = moment();
        const datePickerTime = moment(dateTime);
        return !moment(datePickerTime).isAfter(currentTime);
    }

    static createDropdownOptions = (values, isTimezone = false) => {
        let dropdownOptions = [];
        values.forEach(item => {
            dropdownOptions.push(
                new DropdownOption({
                    label: isTimezone
                        ? item.timeZone
                        : item.label
                        ? item.label
                        : item.name
                        ? item.name
                        : '',
                    value: item.id,
                    id: item.id,
                })
            );
        });
        return dropdownOptions;
    };

    static onGridFilter = (event, obj) => {
        const gridObject = { ...obj };
        const eventFilter = _.get(event, 'filters', {});

        // Checks if the eventFilter is empty object
        if (Object.entries(eventFilter).length === 0 && eventFilter.constructor === Object) {
            gridObject.gridFilter = [];
        } else {
            for (const filter in eventFilter) {
                if (eventFilter.hasOwnProperty(filter)) {
                    gridObject.gridFilter = gridObject.gridFilter.filter(
                        obj => obj.hasOwnProperty('field') && obj['field'] !== filter
                    );
                    gridObject.gridFilter.push({
                        field: filter,
                        value: _.get(eventFilter[filter], 'value', ''),
                        operation: _.get(eventFilter[filter], 'matchMode', ''),
                    });
                }
            }
            gridObject.gridPage = 0;
        }

        return gridObject;
    };

    static onGridSort = (event, obj) => {
        const gridObject = { ...obj };
        const sortField = _.get(event, 'sortField', '');
        const sortOrder = _.get(event, 'sortOrder', '') === 1 ? 'asc' : 'desc';

        gridObject.gridSort = [];
        gridObject.gridSort.push({
            field: sortField,
            direction: sortOrder,
        });
        gridObject.gridPage = 0;

        return gridObject;
    };

    static onGridPage = (event, obj) => {
        const gridObject = { ...obj };
        gridObject.gridPage = event.page + 1;

        return gridObject;
    };

    static createSchemaFromFormConfig = (config, dateFormat, locations) => {
        let schema = {};
        if (locations) {
            RecruitService.addLocationsToSchema(schema, locations);
        }

        for (let i = 0; i < config.length; i++) {
            if (_.get(config[i], 'isPresent', true)) {
                const name = _.get(config[i], 'content', `default${i}`);
                const id = _.get(config[i], 'id', `0`);
                // The itemKey has to be unique, but the custom questions could have multiple keys
                // that are the same. Therefore we have to check if the schema has already the key,
                // and if it has it, the key will be formed as name + id. On job apply will be described
                // how to get the dynamic id with a helperObj.
                const itemKey = schema.hasOwnProperty(name) ? name + id : name;
                const field = {};
                const fieldName = _.get(config[i], 'type.fieldName', '');
                const fieldId = _.get(config[i], 'type.id', '');

                field.label =
                    fieldName === 'resume' ? 'Resume' : _.get(config[i], 'content', `Field${i}`);
                // if(field.validation.required === true)
                //     field.label += field.label;
                field.accept = null;
                field.condition = null;
                field.touched = false;
                field.validation = {};
                field.validation.required = _.get(config[i], 'isRequired', false);
                field.valid = !field.validation.required;
                field.dynamicControlId = fieldId;
                field.elementConfig = {};
                switch (fieldId) {
                    case RecruitService.DYNAMIC_FILE_ID:
                    case RecruitService.DYNAMIC_RESUME_ID:
                        field.elementType = 'file-drop';
                        field.accept =
                            fieldId === RecruitService.DYNAMIC_RESUME_ID
                                ? Files.PDF + Files.DOC + Files.DOCX
                                : Files.EXT_RAR +
                                  Files.JPG +
                                  Files.PNG +
                                  Files.DOC +
                                  Files.DOCX +
                                  Files.PDF +
                                  Files.ZIP +
                                  Files.RAR;
                        field.condition = 'condition';
                        field.reference = 'resumeUpload';
                        field.value = { size: 0 };
                        break;
                    case RecruitService.DYNAMIC_WEBSITE_ID:
                        field.elementConfig.type = 'text';
                        field.elementType = 'input';
                        field.validation.pattern = linksValidationPattern;
                        break;
                    case RecruitService.DYNAMIC_NAME_ID:
                        field.elementConfig.type = 'text';
                        field.elementType = 'input';
                        field.validation.pattern = nameValidationPattern;
                        break;
                    case RecruitService.DYNAMIC_PHONE_ID:
                        field.elementConfig.type = 'text';
                        field.elementType = 'input';
                        field.validation.pattern = phoneValidationPattern;
                        break;
                    case RecruitService.DYNAMIC_EMAIL_ID:
                        field.elementConfig.type = 'text';
                        field.elementType = 'input';
                        field.validation.pattern = mailValidationPattern;
                        break;
                    case RecruitService.DYNAMIC_DESIRED_SALARY_ID:
                        field.elementConfig.type = 'text';
                        field.elementType = 'input';
                        break;
                    case RecruitService.DYNAMIC_YES_NO_ID:
                        field.elementType = 'drop-down';
                        field.elementConfig.options = [
                            {
                                label: 'Yes',
                                value: 'true',
                            },
                            {
                                label: 'No',
                                value: 'false',
                            },
                        ];
                        field.value = {
                            label: 'No',
                            value: 'false',
                        };
                        break;
                    case RecruitService.DYNAMIC_DATE_AVAILABLE_ID:
                        // field.viewDate = moment().add(1, 'days')._d;
                        // field.validation.pattern = /^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/\d{2}$/;
                        field.elementType = 'datepicker';
                        field.elementConfig.readonlyInput = true;
                        field.elementConfig.minDate = moment()._d;
                        field.dateFormat = RecruitService.getDPDateFormat(dateFormat);
                        field.validation.futureDateValidation = false;
                        field.value = null;
                        break;
                    case RecruitService.DYNAMIC_MULTIPLE_CHOICE_ID:
                    case RecruitService.DYNAMIC_SINGLE_CHOICE_ID:
                        let values = _.get(config[i], 'options', []).map(option => {
                            return {
                                label: option,
                                value: option,
                            };
                        });

                        field.elementType = 'list-box';
                        field.elementConfig.options = values;
                        field.elementConfig.multiple = fieldId === 16;
                        break;
                    case RecruitService.DYNAMIC_LINKEDIN_ID:
                        field.validation.pattern = linksValidationPattern;
                        field.elementType = 'input';
                        field.elementConfig.type = 'text';
                        break;
                    case RecruitService.DYNAMIC_COVER_LETTER_ID:
                    case RecruitService.DYNAMIC_TEXT_ID:
                        field.elementType = 'text-area';
                        break;
                    default:
                        field.elementType = 'input';
                        field.elementConfig.type = 'text';
                }
                field.elementConfig.className = 'form-config';
                field.errorMsg =
                    field.elementType === 'file-drop'
                        ? 'Please upload a valid file'
                        : 'Please fill with valid value';
                schema[itemKey] = field;
            }
        }

        return schema;
    };

    static addLocationsToSchema = (schema, locations) => {
        const name = 'Location',
            itemKey = name,
            field = {},
            fieldName = name;

        field.label = fieldName;
        field.touched = false;
        field.validation = {};
        field.validation.required = true;
        field.valid = !field.validation.required;
        field.elementConfig = {};
        // Adding the previously specified ID for the Locations, so we can identify it in the component
        field.dynamicControlId = RecruitService.DYNAMIC_LOCATIONS_ID;
        field.elementConfig.className = 'form-config';
        field.errorMsg = 'Please choose a location';
        field.elementType = 'drop-down';
        field.elementConfig.options = [];

        for (let i = 0; i < locations.length; i++) {
            const location = locations[i];
            field.elementConfig.options.push({
                label: `${location.city !== '' ? location.city + ',' : ''} ${location.address}`,
                value: location.id,
            });
            field.value = {
                label: '',
                value: '',
            };
        }
        schema[itemKey] = field;
        return schema;
    };

    // Maps each format from global settings to the corresponding one of the date picker
    // Could be optimized with str.replace and regex
    static getDPDateFormat = settingsFormat => {
        let datePickerDateFormat = '';
        switch (settingsFormat) {
            case 'dd/MM/yyyy':
                datePickerDateFormat = 'dd/mm/yy';
                break;
            case 'dd-MMM-yy':
                datePickerDateFormat = 'dd-mm-y';
                break;
            case 'dd-MMM-yyyy':
                datePickerDateFormat = 'dd-m-yy';
                break;
            case 'dd.MM.yy':
                datePickerDateFormat = 'dd.mm.y';
                break;
            case 'dd.MM.yyyy':
                datePickerDateFormat = 'dd.mm.yy';
                break;
            case 'MM/dd/yyyy':
                datePickerDateFormat = 'mm/dd/yy';
                break;
            case 'MM/dd/yy':
                datePickerDateFormat = 'mm/dd/y';
                break;
            case 'yyyy/MM/dd':
                datePickerDateFormat = 'yy/mm/dd';
                break;
            default:
                datePickerDateFormat = RecruitService.datepickerFormat;
                break;
        }

        return datePickerDateFormat;
    };

    static scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop);

    // Used to cut off file names all over ther app
    // Keeps the extension
    static truncateLongerFileName = fileName => {
        const fileNameLength = fileName.length,
            maxLength = 20;

        if (fileNameLength > maxLength) {
            let nameTruncated =
                    fileName.split('.').slice(0, -1).join('.').substr(0, maxLength) + '...',
                fileExtension = fileName.split('.')[fileName.split('.').length - 1];
            return nameTruncated + fileExtension;
        }
        return fileName;
    };

    static convertTime = (timeStamp, timeZone) => {
        let timeUtc = null;
        let objCreated = null;

        if (timeZone) {
            timeUtc = moment.utc().tz(timeZone);
            objCreated = moment.utc(timeStamp).tz(timeZone);
        } else {
            timeUtc = moment.utc();
            objCreated = moment.utc(timeStamp);
        }
        const today = moment().hours(0).minutes(0).seconds(0).milliseconds(0);
        const objDay = moment(timeStamp).hours(0).minutes(0).seconds(0).milliseconds(0);

        const minutes = timeUtc.diff(objCreated, 'minutes');
        const hours = timeUtc.diff(objCreated, 'hours');
        const days = today.diff(objDay, 'days');
        const years = timeUtc.diff(objCreated, 'years');

        if (minutes < 1) {
            return 'just now';
        }
        if (minutes >= 1 && minutes < 60) {
            return minutes + 'm ago';
        }
        if (hours >= 0 && hours <= 24) {
            return hours + 'h ago';
        }
        if (days >= 1 && days < 365) {
            return days + 'd ago';
        }
        if (days >= 365) {
            if (days === 365 && objCreated.isLeapYear()) {
                return 1 + 'y ago';
            } else {
                return years + 'y ago';
            }
        }
    };

    static convertCandidateTime = (timeStamp, timeZone, shortenLabel) => {
        let timeUtc = null;
        let candidateCreated = null;

        if (timeZone) {
            timeUtc = moment.utc().tz(timeZone);
            candidateCreated = moment.utc(timeStamp).tz(timeZone);
        } else {
            timeUtc = moment.utc();
            candidateCreated = moment.utc(timeStamp);
        }

        const today = moment().hours(0).minutes(0).seconds(0).milliseconds(0);
        const candidateDay = moment(timeStamp).hours(0).minutes(0).seconds(0).milliseconds(0);

        const hours = timeUtc.diff(candidateCreated, 'hours');
        const days = today.diff(candidateDay, 'days');
        const years = timeUtc.diff(candidateCreated, 'years');

        if (hours >= 0 && hours <= 48) {
            return shortenLabel ? 'new' : 'New';
        }
        if (days > 0 && days < 365) {
            return shortenLabel ? days + 'd' : days + ' days ago';
        }
        if (days >= 365) {
            if (days === 365 && candidateCreated.isLeapYear()) {
                return shortenLabel ? 1 + 'y' : 1 + ' year ago';
            } else {
                return shortenLabel ? years + 'y' : years + ' years ago';
            }
        }
    };

    static getUserLetters = name => {
        let fullName = name.split(' ');

        if (fullName.length === 1) {
            return `${fullName[0].charAt(0)}`;
        } else {
            return `${fullName[0].charAt(0)}${fullName[fullName.length - 1].charAt(0)}`;
        }
    };

    static getQueryObject(queryString) {
        let queryObject = {};
        let pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
        for (let i = 0; i < pairs.length; i++) {
            let pair = pairs[i].split('=');
            queryObject[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
        }
        return queryObject;
    }

    static updateFilter = (e, field, filter) => {
        const exists = filter.find(filter => {
            return filter.field === field;
        });
        const existsIsQualifiedFilter = filter.find(filter => {
            return filter.field === 'jobWithStages.isQualified';
        });

        // if dropdown option is not 'All'
        if (e.value !== 'all') {
            if (
                field === 'jobWithStages.disqualifyReason.name' ||
                field === 'jobWithStages.isQualified'
            ) {
                //always remove isQualified filter before adding other filters
                if (existsIsQualifiedFilter) {
                    const index = filter.indexOf(existsIsQualifiedFilter);
                    filter.splice(index, 1);
                }
                if (e.value === 'Qualified (All)' || e.value === 'Disqualified (All)') {
                    if (!exists) {
                        //add isQualified filter
                        filter.push({
                            value: e.label === 'Qualified (All)' ? true : false,
                            operation: 'equal',
                            field: 'jobWithStages.isQualified',
                        });
                    } else {
                        // if exists change it to match the new value
                        let index = filter.indexOf(exists);
                        filter[index] = {
                            value: e.label === 'Qualified (All)' ? true : false,
                            operation: 'equal',
                            field: 'jobWithStages.isQualified',
                        };
                    }
                    return;
                }
            }
            if (!exists) {
                // if doesn't exist push it
                filter.push({
                    value: e.label,
                    operation: 'equal',
                    field: field,
                });
            } else {
                // if exists change it to match the new value
                let index = filter.indexOf(exists);
                filter[index] = {
                    value: e.label,
                    operation: 'equal',
                    field: field,
                };
            }
            // if dropdown option is 'All'
        } else {
            // check if such filter exists
            if (exists) {
                // if exists remove it from the list
                const index = filter.indexOf(exists);
                filter.splice(index, 1);
            }
            if (existsIsQualifiedFilter) {
                const index = filter.indexOf(existsIsQualifiedFilter);
                filter.splice(index, 1);
            }
        }
    };

    static createDropdownObject = (
        list,
        filterName,
        dropdownFilterValues,
        filterFunction,
        allOption
    ) => {
        let dropdownOptions = [];
        let obj = {};
        list.forEach(item => {
            dropdownOptions.push({
                label: item.name ? item.name : item.title ? item.title : item,
                value: item.id ? item.id : item,
                id: item.id ? item.id : item,
            });
        });
        dropdownOptions.sort((a, b) => {
            return a.label > b.label ? -1 : 1;
        });

        if (filterName === 'jobWithStages.disqualifyReason.name') {
            dropdownOptions.push({
                label: 'Disqualified (All)',
                value: 'Disqualified (All)',
                id: 'Disqualified (All)',
            });
            dropdownOptions.push({
                label: 'Qualified (All)',
                value: 'Qualified (All)',
                id: 'Qualified (All)',
            });
        }

        dropdownOptions.push(allOption);

        obj['options'] = dropdownOptions.reverse();
        obj['filterFunction'] = filterFunction;
        obj['selectedOption'] = allOption;

        let tmpObj = { ...dropdownFilterValues };
        tmpObj[filterName] = obj;
        return tmpObj;
    };

    static clearDropdownFilters = (fields, filter) => {
        let updatedFilter = [];
        filter.forEach(f => {
            if (!fields.includes(f.field)) {
                updatedFilter.push(f);
            }
        });
        return updatedFilter;
    };
}
