export class HttpRequestsConfig {
    headers = null;

    constructor(httpRequestsConfig) {
        if (httpRequestsConfig === undefined) {
            httpRequestsConfig = {
                'headers': new HttpHeaders({})
            };
        }

        this.headers = new HttpHeaders(httpRequestsConfig.headers);
    }
}

export class HttpHeaders {
    Authorization = '';

    constructor(httpHeaders) {
        if (httpHeaders === undefined) {
            httpHeaders = {
                'Authorization': ''
            };
        }

        this.Authorization = httpHeaders.Authorization;
    }
}
